import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Button,
  Tooltip,
  CircularProgress,
  Snackbar,
  Alert,
} from '@influenze/ui-lib';
import { StyledTextField, FlexContainer } from './index.styles';
const ContactMeForm = ({ passedRef }) => {
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackBarStatus, setSnackBarStatus] = useState(false);

  useEffect(() => {
    if (name === '' || phone === '' || email === '' || message === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name, phone, email, message]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('entry.2005620554', name);
    formData.append('entry.1166974658', phone);
    formData.append('entry.1045781291', email);
    formData.append('entry.839337160', message);

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        'https://docs.google.com/forms/d/e/1FAIpQLSeRSh6Mpe97R9-_gM5GqF-Z_befAOsJcDZgeEIcEllrxMxZyQ/formResponse',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors',
        },
      );
      if (response.ok || response.status === 0) {
        setSubmissionStatus('success');
        setSnackBarStatus(true);
      } else {
        setSubmissionStatus('failure');
        setSnackBarStatus(true);
      }
      setName('');
      setPhone('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmissionStatus('failure');
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackBarStatus}
        onClose={() => setSnackBarStatus(false)}
        autoHideDuration={6000}
      >
        <Alert
          severity={submissionStatus === 'success' ? 'success' : 'warning'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {submissionStatus === 'success'
            ? 'Details Submitted Successfully'
            : 'Details Submission Failed'}
        </Alert>
      </Snackbar>
    );
  };
  const renderForm = () => {
    return (
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSeRSh6Mpe97R9-_gM5GqF-Z_befAOsJcDZgeEIcEllrxMxZyQ/formResponse"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Text
            variant="h2"
            style={{
              color: '#dddddd',
              alignSelf: 'center',
              fontWeight: 600,
              margin: '1%',
              textAlign: 'center',
            }}
          >
            Just say Hello!
          </Text>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              width: '100%',
            }}
          >
            <StyledTextField
              name="entry.2005620554"
              label="Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              required
            />
            <StyledTextField
              name="entry.1166974658"
              value={phone}
              label="Phone number"
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              required
            />
          </div>
          <StyledTextField
            name="entry.1045781291"
            label="Email"
            value={email}
            type="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
          <StyledTextField
            name="entry.839337160"
            label="Message"
            value={message}
            multiline
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            required
            rows={10}
          />
          <Tooltip title={disabled ? 'Fill in all the details' : 'Submit'}>
            <Button
              type="submit"
              variant="outlined"
              style={{
                color: '#fff',
                width: '16%',
                borderRadius: '12px',
                borderColor: '#fff',
              }}
            >
              Submit
            </Button>
          </Tooltip>
        </div>
      </form>
    );
  };
  return (
    <FlexContainer ref={passedRef}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '10px',
        }}
      >
        {submissionStatus === 'success' ? (
          <div
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            {renderSnackBar()}
          </div>
        ) : (
          (submissionStatus === 'failure' || error) && (
            <div
              style={{
                display: 'flex',
                gap: '20px',
              }}
            >
              {renderSnackBar()}
            </div>
          )
        )}

        <div
          style={{
            display: 'flex',
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            minHeight: 'auto',
            flex: 1,
          }}
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                minWidth: '200px',
              }}
            >
              <CircularProgress size={50} style={{ alignSelf: 'center' }} />
            </div>
          ) : (
            renderForm()
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="h3"
            style={{
              color: '#dddddd',
              alignSelf: 'center',
              fontWeight: 600,
              margin: '2%',
              textAlign: 'center',
            }}
          >
            Contact Us
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              color: '#dddddd',
              width: '80%',
              margin: '2%',
              textAlign: 'center',
              fontFamily: 'poppins, sans-serif',
            }}
          >
            We are here to help you! Please fill in the form below with your
            contact details and a brief description of your inquiry or issue.
            The more information you provide, the better we can assist you. Rest
            assured, we will get back to you as soon as possible.
          </Text>
        </div>
      </div>
    </FlexContainer>
  );
};
ContactMeForm.propTypes = {
  passedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ContactMeForm;
