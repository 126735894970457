import './App.css';
import HomeScreen from './containers/home/screen';

function App() {
  return (
    <div className="background">
      <HomeScreen />
    </div>
  );
}

export default App;
